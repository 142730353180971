@import '~assets/less/desktop-colors';
@keyframes skeleton-loading {
    0% {
        background-position: -150px;
    }
    100% {
        background-position: 200px;
    }
}
.skeleton-background-white {
    animation: skeleton-loading 1.7s infinite linear;
    background: @color-lighter
        linear-gradient(to left, @color-lighter 0%, @color-light 20%, @color-lighter 40%, @color-lighter 100%) no-repeat;
    color: transparent;
    cursor: default;
    &::selection {
        color: transparent;
    }
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
.skeleton-background-light {
    animation: skeleton-loading 1.7s infinite linear;
    background: @color-light
        linear-gradient(to left, @color-light 0%, @color-nobel 20%, @color-light 40%, @color-light 100%) no-repeat;
    color: transparent;
    cursor: default;
    &::selection {
        color: transparent;
    }
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}
#loading-skeleton-container {
    position: relative;
}
