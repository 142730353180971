@import '~assets/less/desktop-colors';

body {
    font-family: Verdana, Arial, Helvetica, sans-serif;
    color: @text-primary;
    margin: 0;

    &:has(#loading-skeleton-container, page-forwarding-waiting-modal),
    &:has(modal-backdrop:not([hidden]) waiting-modal) {
        overflow: hidden;
    }

    &.c24-cc-visible {
        position: relative;
    }

    .mat-error {
        font-size: 11px;
    }
}

.layout {
    margin: 0 auto 14px auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 994px;
    background-color: @background-primary;

    &--fluid {
        min-width: 994px;
        max-width: 1280px;
        width: auto;
    }
}
