// styles for cobrowsing/alternative offer
.alternative-offer {
    comparison-header-tariff-row.is-sticky {
        .main-container {
            padding: 0 !important;
        }

        .sticky-container {
            width: calc(100% - 30px) !important;
            max-width: 1280px;
            left: auto !important;
            right: auto !important;
        }
    }
}
