// css override for costsharingFilter
.ngx-slider {
    .ngx-slider-bar-wrapper {
        margin-top: -20px !important;
    }

    .ngx-slider-tick-legend {
        font-size: 12px;
        color: @color-nobel;
        cursor: pointer;
        line-height: 13px;
        height: 20px;
    }

    .ngx-slider-ticks {
        top: -9px !important;
        margin-left: -6px !important;
    }

    .ngx-slider-tick {
        border: 2px solid @color-inverted;
        height: 10px !important;
        width: 10px !important;
        position: relative;

        &:first-child {
            &:before {
                content: '';
                height: 5px;
                width: 10px;
                background: @color-inverted;
                position: absolute;
                left: -11px;
                top: 3px;
            }
        }

        &:last-child {
            &:after {
                content: '';
                height: 5px;
                width: 10px;
                background: @color-inverted;
                position: absolute;
                right: -11px;
                top: 3px;
            }
        }
    }

    .ngx-slider-selection {
        background-color: @color-primary !important;
    }

    .ngx-slider-tick.ngx-slider-selected {
        background-color: @color-primary !important;
        border: 2px solid @color-inverted;
    }

    .ngx-slider-pointer {
        border: 2px solid @color-primary !important;
        background: @color-inverted !important;
        border-radius: 50% !important;
        width: 22px !important;
        height: 22px !important;

        &:after {
            border: 1px solid @color-primary;
            top: 6px !important;
            left: 6px !important;
            border-radius: 50% !important;
            background: @color-primary !important;
        }
    }

    .ngx-slider-pointer.ngx-slider-active:after {
        background-color: @color-primary !important;
    }

    .ngx-slider-bubble {
        color: @color-inverted !important;
        background: @color-primary;
        margin-bottom: 8px;
        font-size: 11px !important;
        font-weight: 700 !important;
        padding: 4px !important;
        border-radius: 3px;
        height: 24px;
        width: 40px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}
