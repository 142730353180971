@import '~assets/less/desktop-colors';

mat-error {
    -webkit-font-smoothing: antialiased;
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
    .mdc-notched-outline__notch {
    border-left: none;
}

.mat-mdc-radio-group.c24-ng-mat-radio-row .mat-mdc-radio-button:not(:last-of-type)::after {
    transform: none;
}

.mat-mdc-form-field-hint {
    font-size: 11px;
    color: @text-light !important;
}

.mat-calendar-body-cell-content::before {
    margin: 0 !important;
}
