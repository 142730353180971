@import '~assets/less/desktop-colors';

/* You can add global styles to this file, and also import other style files */
@import (css) '@angular/cdk/overlay-prebuilt.css';
@import '~@angular/cdk/a11y-prebuilt.css';

@import '~node_modules/@vv-ham/ng-desktop-forms/src/styles';
@import '~node_modules/@vv-ham/ng-desktop-tooltip/src/styles';
@import '~node_modules/@vv-ham/ng-desktop-material-styles/c24-ng-desktop-material-theme.css';

@import 'less/layout';
@import 'less/loading-skeleton';

@import 'less/overrides/alternative-offer';
@import 'less/overrides/cdk-overlay';
@import 'less/overrides/ngx-slider';
@import 'less/overrides/sso-login-layer';

@import './assets/styles/material';

html {
    scroll-behavior: smooth;
}

.mdc-snackbar {
    &.success {
        --mdc-snackbar-container-color: @color-positive !important;
        color: #fff;
    }

    &.error {
        --mdc-snackbar-container-color: @color-dark-red !important;
        color: #fff;
    }
}
